import { Snackbar } from '@mui/material'
import React, { FC, useMemo } from 'react'
import useSnackbar from '../../hooks/useSnackbar';
import { SNACKBAR_SUCCESS } from '../../routes';

type SnackParams = null | {
  message: string
}

const SuccessBar: FC = () => {

  const { activeSnackbar, setActiveSnackbar, activeSnackbarParams } = useSnackbar()

  const params = useMemo<SnackParams>(() => {
    if (!activeSnackbar) return null
    if (activeSnackbar === SNACKBAR_SUCCESS) {
      if (activeSnackbarParams && activeSnackbarParams.message) {
        return {
          message: activeSnackbarParams.message
        }
      } else return null
    } else return null
  }, [activeSnackbar, activeSnackbarParams])

  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setActiveSnackbar(null);
  };

  return (
    <div className='Snackbar--wrapper'>
      <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open={activeSnackbar === SNACKBAR_SUCCESS}
          autoHideDuration={3000}
          onClose={handleClose}
      >
        <div className='flex w-full items-center bg-[#EBEBEB] rounded-lg py-2.5 px-2 gap-3'>
          <div className='flex flex-col text-black'>
            <div className='text-sm'>
              {`✅ ${params?.message}`}
            </div>
          </div>
        </div>
      </Snackbar>
    </div>
  )
}

export default SuccessBar