import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from "@nextui-org/react";
import { t } from "i18next";
import React, {FC, useEffect, useMemo, useRef, useState} from "react";
import { MODAL_WALLET_INPUT } from "../routes";
import useModal from "../hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import Icon24Close from "../assets/icons/Icon24Close";
import { DefaultStateType, getDispatchObject, SET_WALLET_ADDRESS } from "../store/reducer";
import { fetchData } from "../utils/api";
import { PublicKey } from '@solana/web3.js'

// @ts-ignore
const tg = window['Telegram'].WebApp;

const WalletInputModal: FC = () => {
  
  const {activeModal, setActiveModal, activeModalParams} = useModal();
  const [currentAddress, setCurrentAddress] = useState<string | null>(null)
  const [inFocus, setInFocus] = useState<null | 'addr'>(null)
  const walletAddress = useSelector((s: DefaultStateType) => s.walletAddress);
  const [invalidVal, setInvalidVal] = useState(false)

  const addrRef = useRef<HTMLInputElement>(null)

  const dispatch = useDispatch()

  const handleInput = (newValue: HTMLInputElement) => {
    if (walletAddress) return
    if (!newValue.value) {
      setCurrentAddress(null)
      return
    }

    setCurrentAddress(newValue.value)
  }

  const confirm = (addr: string) => {
    tg.showPopup({
      title: t('walletConfirmTitle'),
      message: t('walletConfirmText'),
      buttons: [
          {
              id: 'confirm',
              type: 'default',
              text: t('walletConfirmButton')
          },
          {
              id: 'cancel',
              type: 'cancel',
              text: t('walletConfirmCancelButton')
          },
      ],
  }, (id: string) => {
      if (id === 'confirm') {
        saveAddress(addr);
      }
  });
}

  const saveAddress = async (addr: string) => { 
    //requst
    const response = await fetchData('/wallets/attach', {
      address: addr
    })
    if (response.result) {
      dispatch(getDispatchObject(SET_WALLET_ADDRESS, currentAddress))
      beforeLeaveModal()
      setActiveModal(null)
    }
  }

  const exchange = async () => {
    if (!currentAddress) return

    try {

      const addr = new PublicKey(currentAddress)
  
      const isValid = PublicKey.isOnCurve(addr.toBytes()) 
    
      if (isValid) {
        // request
        confirm(currentAddress)
  
      } else {
        setInvalidVal(true)
      }
    } catch {
      setInvalidVal(true)
    }
  }

  const beforeLeaveModal = () => {
    if (addrRef.current) addrRef.current.blur()
    setInvalidVal(false)
    setInFocus(null)
  }

  return (
    <Modal
      isOpen={activeModal === MODAL_WALLET_INPUT}
      placement='top-center'
      scrollBehavior='inside'
      className='bg-[#19192B] relative mt-8'
      onClose={() => {
        beforeLeaveModal()
        setActiveModal(null)
      }}
    >
        <ModalContent >
            {(onClose) => (
                <>
                    <ModalHeader></ModalHeader>
                    <ModalBody className="px-6 gap-6">
                        <div className='flex flex-col items-center justify-center'>
                          <p className="text-3xl text-white">
                            {t('walletInputTitle')}
                          </p>
                          <p className='text-base font-normal text-white/95 text-center mt-4'>
                            {t(walletAddress ? 'walletAlreadyIn' : 'walletInputDescription')}
                          </p>
                          <div className="mt-5 flex flex-col gap-6 w-full">
                            <div className="Exchange--USDT relative">
                              <input
                                ref={addrRef}
                                type='text'
                                id="addr"
                                className={`w-full border-2 border-solid outline-none px-4 py-3 rounded-2xl bg-transparent focus:border-[#4F3CC8] ${invalidVal ? 'border-red-500' : 'border-white/10'}`}
                                value={currentAddress ?? ""}
                                onChange={e => {
                                  handleInput(e.target)
                                  setInvalidVal(false)
                                }}
                                onFocus={() => setInFocus('addr')}
                                onBlur={() => setInFocus(null)}
                                required
                                readOnly={walletAddress ? true : false}
                                placeholder={walletAddress ?? t('address')}
                              />
                              <p
                                className={`bg-[rgb(25,25,42)] px-1 font-semibold absolute top-[-8px] left-6 ${inFocus === 'addr' ? 'text-[#4F3CC8]' : (invalidVal ? 'text-red-500' : 'text-[#4B526A]')}`}>
                                {t('walletInputSOL')}
                              </p>
                              {
                                currentAddress && inFocus !== 'addr' ?
                                <div 
                                  className="absolute right-4 top-2.5 cursor-pointer"
                                  onClick={() => {
                                    setCurrentAddress('')
                                    setInvalidVal(false)
                                  }}
                                >
                                  <Icon24Close />
                                </div> : null 
                              }
                            </div>
                          </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className='flex-col'>
                      {
                        walletAddress ? 
                        <Button
                          fullWidth
                          className='bg-[var(--link\_color)] opacity-100 rounded-lg px-3 h-fit py-4 text-lg'
                          onPress={() => onClose()}
                        >
                          {t('modalOkay')}
                        </Button> :
                        <Button
                          fullWidth
                          className='bg-[var(--link\_color)] opacity-100 rounded-lg px-3 h-fit py-4 text-lg'
                          onPress={() => exchange()}
                        >
                          {t('walletSave')}
                        </Button>
                      }
                    </ModalFooter>
                </>
            )}
        </ModalContent>
    </Modal>
  )
}

export default WalletInputModal